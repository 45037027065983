import { ref, watch, computed } from "@vue/composition-api";
import axiosIns from "@/libs/axios";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useRecordList() {
  // Use toast
  const toast = useToast();

  const refRecordListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "remark", label: "項目 "},
    { key: "amount", label: "收入 / 支出", sortable: true  },
    { key: "created_at", label: "日期", sortable: true  },
  ];
  const perPage = ref(10);
  const totalRecords = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refRecordListTable.value
      ? refRecordListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    };
  });
  


  const refetchData = () => {
    refRecordListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery],
    () => {
      refetchData();
    }
  );

  const fetchRecords = (ctx, callback) => {
    axiosIns
      .get("/coin/transaction", { params:{
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      }})
      .then((response) => {
        const { coins, total } = response.data;
        callback(coins);
        totalRecords.value = total;
      })
      .catch((err) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching record list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchRecords,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRecordListTable,
    ability,
    refetchData,
  };
}
